module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","name":"MentorMe - Primul program de mentorat din Moldova","short_name":"MentorMe","description":"Conectăm și ajutăm gratuit tinerii să-și urmeze studiile universitare, să-și accelereze dezvoltarea profesională și să identifice noi oportunități de afaceri.","start_url":"/","theme_color":"#fff","display":"standalone","icon":"src/assets/mentorme_logo.png","legacy":true,"theme_color_in_head":false,"crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"cacheDigest":"b93944cfc4f91759545168aef56aefea"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-PNEDPE7DMD"],"gtagConfig":{"optimize_id":"GTM-MJJQSWW"},"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"536776876741920"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
